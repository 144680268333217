.splide {
  padding: 20px 0;
}

.splide__slide img {
  display: block;
  width: 100%;
  border-radius: 8px;
  transition: transform 0.5s ease;
  transform: scale(0.7);
  transform-origin: center center;
}

.splide__slide.is-active img {
  transform: scale(1.1);
}

.splide .splide__arrow {
  top: 0;
  bottom: 0;
  height: 100%;
  transform: none;
  border-radius: unset;
  width: 50px;
  opacity: 0.9;
}
.splide .splide__arrow svg {
  fill: #d6061e;
  width: 24px;
  height: 24px;
}

.splide__arrow.splide__arrow--prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 100%);
}

.splide__arrow.splide__arrow--next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 100%);
}



a {
  color: #fff;
  padding: 4px 8px;
}


.left {
  text-align: left;
}